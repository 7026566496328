import React from 'react'

import Header from '../header/Header'
import Footer from '../footer/Footer'
import Home from './Home'



const index = () => {
  return (
    <>
    <Header/>
    <Home/>
   
    
    <Footer/>
    </>
  )
}

export default index
