import React from 'react'

import GraphicDesign from './GraphicDesign'
import Footer from '../../header/Header'
import Header from '../../footer/Footer'


const graphicindex = () => {
  return (
    <>

    <Header/>
    <GraphicDesign/>
    <Footer/>
      
    </>
  )
}

export default graphicindex
