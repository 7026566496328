import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'

import Contact from './contact'


function contactindex() {
  return (
    <div>
      <Header/>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default contactindex
