import React from 'react'

import Smo from './Smo'
import Footer from '../../header/Header'
import Header from '../../footer/Footer'


const smoindex = () => {
  return (
    <>

    <Header/>
    <Smo/>
    <Footer/>
      
    </>
  )
}

export default smoindex
