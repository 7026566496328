import React from 'react'

function Marketing() {
    return (
        <>
            {/* <!-- Start Bread Crumb Area Home 1 --> */}
            <section class="bread-crumb-area">
                <div class="container">
                    <div class="bread-crumb-wrapper">
                        <a href="index.html">Home</a>
                        <span>
                            <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.90625 7.53125L2.84375 13.625C2.53125 13.9062 2.0625 13.9062 1.78125 13.625L1.0625 12.9062C0.78125 12.625 0.78125 12.1562 1.0625 11.8438L5.875 7L1.0625 2.1875C0.78125 1.875 0.78125 1.40625 1.0625 1.125L1.78125 0.40625C2.0625 0.125 2.53125 0.125 2.84375 0.40625L8.90625 6.5C9.1875 6.78125 9.1875 7.25 8.90625 7.53125Z"
                                    fill="white" />
                            </svg>
                        </span>
                        <a href="#" class="current-page">service Details</a>
                    </div>
                    <div class="bread-crumb-title">
                        <h2 class="split-collab">digital Marketing</h2>
                    </div>
                </div>
            </section>
            {/* <!-- End Bread Crumb Area Home 1 --> */}

            {/* <!-- Start Blog Details Area --> */}
            <section class="blog-details-area">
                <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" class="blog-details-area-shep-1 blur-1" />
                <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" class="blog-details-area-shep-2 blur-1" />
                <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" class="blog-details-area-shep-3 blur-1" />
                <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" class="blog-details-area-shep-4 blur-1" />
                <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" class="blog-details-area-shep-5 blur-1" />
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8">
                            <div class="blog-details-inner">
                                <div class="blog-details-banner vre-reveal-one">
                                    <img src="assets/images/blog-details/blog-details-banner-1.webp" alt="VRE"
                                        class="vre-reveal-image-one" />
                                </div>
                                <h4 class="font-size-1-35 split-collab">Boost up your bussiness with the best Digital Marketing services company in Lucknow</h4>
                                <p class="font-size-1-16">Are you seeking to amplify your brand’s online visibility and drive more conversions? Look no further! At UP Global Technology, we offer top-notch digital marketing services designed to cater to your unique business needs. From SEO to PPC and everything in between, we’re dedicated to helping your brand thrive in the digital landscape as the leading the best digital marketing service provider in Lucknow.</p>
                                <div class="blog-details-post-wrapper">
                                    <div class="blog-details-post-1">
                                        <div class="blog-details-post-1-banner vre-reveal-one">
                                            <img src="assets/images/blog-details/blog-details-post-1.webp" alt="VRE"
                                                class="vre-reveal-image-one" />
                                        </div>
                                        <ul>
                                            <li>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1_12477)">
                                                        <path
                                                            d="M24.2867 3.41785C23.919 3.04945 23.3222 3.04881 22.9545 3.41629L11.6511 14.6898L7.57286 10.2605C7.22047 9.87796 6.62467 9.85316 6.24152 10.2055C5.85865 10.5579 5.83414 11.154 6.18654 11.5368L10.929 16.6873C11.1027 16.8761 11.3458 16.9857 11.6021 16.991C11.6089 16.9913 11.6156 16.9913 11.6222 16.9913C11.8713 16.9913 12.1109 16.8924 12.2874 16.7165L24.2849 4.75042C24.6536 4.38299 24.6542 3.78626 24.2867 3.41785Z"
                                                            fill="#CCDEFF" />
                                                        <path
                                                            d="M24.0578 11.5578C23.5374 11.5578 23.1156 11.9795 23.1156 12.5C23.1156 18.3537 18.3537 23.1156 12.5 23.1156C6.64668 23.1156 1.88442 18.3537 1.88442 12.5C1.88442 6.64668 6.64668 1.88442 12.5 1.88442C13.0204 1.88442 13.4422 1.46265 13.4422 0.942236C13.4422 0.421777 13.0204 0 12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3923 5.60742 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 11.9796 24.5782 11.5578 24.0578 11.5578Z"
                                                            fill="#CCDEFF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_124771">
                                                            <rect width="25" height="25" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span class="font-size-1-18">Find information and begin your application</span>
                                            </li>
                                            <li>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1_12477)">
                                                        <path
                                                            d="M24.2867 3.41785C23.919 3.04945 23.3222 3.04881 22.9545 3.41629L11.6511 14.6898L7.57286 10.2605C7.22047 9.87796 6.62467 9.85316 6.24152 10.2055C5.85865 10.5579 5.83414 11.154 6.18654 11.5368L10.929 16.6873C11.1027 16.8761 11.3458 16.9857 11.6021 16.991C11.6089 16.9913 11.6156 16.9913 11.6222 16.9913C11.8713 16.9913 12.1109 16.8924 12.2874 16.7165L24.2849 4.75042C24.6536 4.38299 24.6542 3.78626 24.2867 3.41785Z"
                                                            fill="#CCDEFF" />
                                                        <path
                                                            d="M24.0578 11.5578C23.5374 11.5578 23.1156 11.9795 23.1156 12.5C23.1156 18.3537 18.3537 23.1156 12.5 23.1156C6.64668 23.1156 1.88442 18.3537 1.88442 12.5C1.88442 6.64668 6.64668 1.88442 12.5 1.88442C13.0204 1.88442 13.4422 1.46265 13.4422 0.942236C13.4422 0.421777 13.0204 0 12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3923 5.60742 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 11.9796 24.5782 11.5578 24.0578 11.5578Z"
                                                            fill="#CCDEFF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_124772">
                                                            <rect width="25" height="25" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span class="font-size-1-18">We provide obtaining entry permits</span>
                                            </li>
                                            <li>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1_12477)">
                                                        <path
                                                            d="M24.2867 3.41785C23.919 3.04945 23.3222 3.04881 22.9545 3.41629L11.6511 14.6898L7.57286 10.2605C7.22047 9.87796 6.62467 9.85316 6.24152 10.2055C5.85865 10.5579 5.83414 11.154 6.18654 11.5368L10.929 16.6873C11.1027 16.8761 11.3458 16.9857 11.6021 16.991C11.6089 16.9913 11.6156 16.9913 11.6222 16.9913C11.8713 16.9913 12.1109 16.8924 12.2874 16.7165L24.2849 4.75042C24.6536 4.38299 24.6542 3.78626 24.2867 3.41785Z"
                                                            fill="#CCDEFF" />
                                                        <path
                                                            d="M24.0578 11.5578C23.5374 11.5578 23.1156 11.9795 23.1156 12.5C23.1156 18.3537 18.3537 23.1156 12.5 23.1156C6.64668 23.1156 1.88442 18.3537 1.88442 12.5C1.88442 6.64668 6.64668 1.88442 12.5 1.88442C13.0204 1.88442 13.4422 1.46265 13.4422 0.942236C13.4422 0.421777 13.0204 0 12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3923 5.60742 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 11.9796 24.5782 11.5578 24.0578 11.5578Z"
                                                            fill="#CCDEFF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_124773">
                                                            <rect width="25" height="25" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span class="font-size-1-18">Ut enim veniam, quis nostrud minim exerc</span>
                                            </li>
                                            <li>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1_12477)">
                                                        <path
                                                            d="M24.2867 3.41785C23.919 3.04945 23.3222 3.04881 22.9545 3.41629L11.6511 14.6898L7.57286 10.2605C7.22047 9.87796 6.62467 9.85316 6.24152 10.2055C5.85865 10.5579 5.83414 11.154 6.18654 11.5368L10.929 16.6873C11.1027 16.8761 11.3458 16.9857 11.6021 16.991C11.6089 16.9913 11.6156 16.9913 11.6222 16.9913C11.8713 16.9913 12.1109 16.8924 12.2874 16.7165L24.2849 4.75042C24.6536 4.38299 24.6542 3.78626 24.2867 3.41785Z"
                                                            fill="#CCDEFF" />
                                                        <path
                                                            d="M24.0578 11.5578C23.5374 11.5578 23.1156 11.9795 23.1156 12.5C23.1156 18.3537 18.3537 23.1156 12.5 23.1156C6.64668 23.1156 1.88442 18.3537 1.88442 12.5C1.88442 6.64668 6.64668 1.88442 12.5 1.88442C13.0204 1.88442 13.4422 1.46265 13.4422 0.942236C13.4422 0.421777 13.0204 0 12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3923 5.60742 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 11.9796 24.5782 11.5578 24.0578 11.5578Z"
                                                            fill="#CCDEFF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_124774">
                                                            <rect width="25" height="25" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span class="font-size-1-18">Apply to travel, study, work or immigrate</span>
                                            </li>
                                            <li>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1_12477)">
                                                        <path
                                                            d="M24.2867 3.41785C23.919 3.04945 23.3222 3.04881 22.9545 3.41629L11.6511 14.6898L7.57286 10.2605C7.22047 9.87796 6.62467 9.85316 6.24152 10.2055C5.85865 10.5579 5.83414 11.154 6.18654 11.5368L10.929 16.6873C11.1027 16.8761 11.3458 16.9857 11.6021 16.991C11.6089 16.9913 11.6156 16.9913 11.6222 16.9913C11.8713 16.9913 12.1109 16.8924 12.2874 16.7165L24.2849 4.75042C24.6536 4.38299 24.6542 3.78626 24.2867 3.41785Z"
                                                            fill="#CCDEFF" />
                                                        <path
                                                            d="M24.0578 11.5578C23.5374 11.5578 23.1156 11.9795 23.1156 12.5C23.1156 18.3537 18.3537 23.1156 12.5 23.1156C6.64668 23.1156 1.88442 18.3537 1.88442 12.5C1.88442 6.64668 6.64668 1.88442 12.5 1.88442C13.0204 1.88442 13.4422 1.46265 13.4422 0.942236C13.4422 0.421777 13.0204 0 12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3923 5.60742 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 11.9796 24.5782 11.5578 24.0578 11.5578Z"
                                                            fill="#CCDEFF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_124775">
                                                            <rect width="25" height="25" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span class="font-size-1-18">Dolor in reprehenderit voluptate velit esse</span>
                                            </li>
                                            <li>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1_12477)">
                                                        <path
                                                            d="M24.2867 3.41785C23.919 3.04945 23.3222 3.04881 22.9545 3.41629L11.6511 14.6898L7.57286 10.2605C7.22047 9.87796 6.62467 9.85316 6.24152 10.2055C5.85865 10.5579 5.83414 11.154 6.18654 11.5368L10.929 16.6873C11.1027 16.8761 11.3458 16.9857 11.6021 16.991C11.6089 16.9913 11.6156 16.9913 11.6222 16.9913C11.8713 16.9913 12.1109 16.8924 12.2874 16.7165L24.2849 4.75042C24.6536 4.38299 24.6542 3.78626 24.2867 3.41785Z"
                                                            fill="#CCDEFF" />
                                                        <path
                                                            d="M24.0578 11.5578C23.5374 11.5578 23.1156 11.9795 23.1156 12.5C23.1156 18.3537 18.3537 23.1156 12.5 23.1156C6.64668 23.1156 1.88442 18.3537 1.88442 12.5C1.88442 6.64668 6.64668 1.88442 12.5 1.88442C13.0204 1.88442 13.4422 1.46265 13.4422 0.942236C13.4422 0.421777 13.0204 0 12.5 0C5.60742 0 0 5.60742 0 12.5C0 19.3923 5.60742 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 11.9796 24.5782 11.5578 24.0578 11.5578Z"
                                                            fill="#CCDEFF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_124776">
                                                            <rect width="25" height="25" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span class="font-size-1-18">Bunt in culpa qui officia deserunt mollit
                                                    anim</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <h5 class="font-size-1-18 split-collab ">WHAT WE DO
                                    </h5>
                                    
                                    <h5 class="font-size-1-30 split-collab">
                                        Our Approach
                                        To Digital Marketing</h5>
                                    <p class="font-size-1-16">At UP Global Technology, we are the architects of your digital success. Our tailored digital marketing strategies are designed to elevate your brand presence and drive measurable results. From engaging content creation to strategic campaign management, we ensure your brand shines across every digital touchpoint. Harness the power of data-driven insights and creative innovation with UP Global Technology to unlock your brand’s full potential in the digital sphere with the best Digital Marketing services company in Lucknow.</p>
                                </div>
                                <div class="blog-details-post-2">
                                    <div class="blog-details-post-2-inner">
                                        <div class="blog-details-post-left vre-reveal-one">
                                            <img src="assets/images/blog-details/blog-details-post-2.webp" alt="VRE"
                                                class="vre-reveal-image-one" />
                                        </div>
                                        <div class="blog-details-post-right vre-reveal-one">
                                            <img src="assets/images/blog-details/blog-details-post-3.webp" alt="VRE"
                                                class="vre-reveal-image-one" />
                                        </div>
                                    </div>
                                    
                                    <h5 class="font-size-1-24 split-collab">Why choose us UP Global's Digital Marketing Services?
                                    </h5>
                                    <p class="font-size-1-16">In today’s digital universe, harnessing the potential of digital marketing is imperative for businesses aiming to stay ahead of the competition. With our comprehensive suite of digital marketing services, you can leverage various online channels to expand your reach, enhance brand awareness, and achieve your business objectives effectively.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4">
                            <div class="sitebar-wrapper">
                                <div class="sitebar-search-bar">
                                    <form action="#">
                                        <input type="text" placeholder="Search" />
                                        <button type="submit">
                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M10.4193 3.646C6.68281 3.646 3.64844 6.68037 3.64844 10.4168C3.64844 14.1533 6.68281 17.1877 10.4193 17.1877C14.1557 17.1877 17.1901 14.1533 17.1901 10.4168C17.1901 6.68037 14.1557 3.646 10.4193 3.646ZM10.4193 4.68766C13.5818 4.68766 16.1484 7.25433 16.1484 10.4168C16.1484 13.5793 13.5818 16.146 10.4193 16.146C7.25677 16.146 4.6901 13.5793 4.6901 10.4168C4.6901 7.25433 7.25677 4.68766 10.4193 4.68766Z"
                                                    fill="black" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M21.2042 20.4647L15.2052 14.4668C15.0021 14.2637 14.6719 14.2637 14.4687 14.4668C14.2656 14.6699 14.2656 15.0001 14.4687 15.2033L20.4667 21.2022C20.6708 21.4053 21 21.4053 21.2042 21.2022C21.4073 20.998 21.4073 20.6689 21.2042 20.4647Z"
                                                    fill="black" />
                                            </svg>
                                        </button>
                                    </form>
                                </div>
                                <div class="sitebar-menu">
                                    <h5 class="font-size-1-24 sitebar-title-1">Main Services</h5>
                                    <ul>
                                        <li><a href="#">App Development <i class="fa-solid fa-angle-right"></i></a></li>
                                        <li><a href="#">SEO Marketing <i class="fa-solid fa-angle-right"></i></a></li>
                                        <li><a href="#">SEO Analysis <i class="fa-solid fa-angle-right"></i></a></li>
                                        <li><a href="#">SEO Optimization <i class="fa-solid fa-angle-right"></i></a></li>
                                        <li><a href="#">Social Media<i class="fa-solid fa-angle-right"></i></a></li>
                                        <li><a href="#">Marketing Strategy <i class="fa-solid fa-angle-right"></i></a></li>
                                    </ul>
                                </div>
                                <div class="sitebar-banner vre-reveal-one">
                                    <a href="#">
                                        <img src="assets/images/sitebar/sitebar-banner.webp" alt="VRE"
                                            class="vre-reveal-image-one" />
                                    </a>
                                </div>
                                <div class="sitebar-tag">
                                    <h5 class="font-size-1-24 sitebar-title-1">Popular Tags</h5>
                                    <div class="sitebar-tag-title">
                                        <a href="#">Social Media</a>
                                        <a href="#">Digital</a>
                                        <a href="#">Marketing</a>
                                        <a href="#">Social</a>
                                        <a href="#">SEO Services</a>
                                        <a href="#">Web SEO</a>
                                        <a href="#">Education</a>
                                        <a href="#">America</a>
                                        <a href="#">Analysis</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Blog Details Area  --> */}


        </>
    )
}

export default Marketing
