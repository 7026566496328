import React from 'react'

import Career from './Career'
import Header from '../header/Header'
import Footer from '../footer/Footer'

const careerindex = () => {
  return (
    <>
    <Header/>
    <Career/>
    <Footer/>
      
    </>
  )
}

export default careerindex
