import React from 'react'

import Seo from './Seo'
import Footer from '../../header/Header'
import Header from '../../footer/Footer'


const seoindex = () => {
  return (
    <>
      
    <Header/>
    <Seo/>
    <Footer/>

    </>
  )
}

export default seoindex
