import React from 'react'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'


const tremsindex = () => {
  return (
    <>
    <Header/>
    <Footer/>
    
      
    </>
  )
}

export default tremsindex
