import React from 'react'
import Marketing from './Marketing'
import Footer from '../../header/Header'
import Header from '../../footer/Footer'

const digitalindex = () => {
  return (
    <>
    
    <Header/>
    <Marketing/>
    <Footer/>

    </>
  )
}

export default digitalindex
