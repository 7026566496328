import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import Projects from './Projects'

const projectsindex = () => {
  return (
    <>

    <Header/>
    <Projects/>
    <Footer/>
      
    </>
  )
}

export default projectsindex
