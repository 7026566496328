import React from 'react'

import MobileAppDevelopment from './MobileAppDevelopment'
import Footer from '../../header/Header'
import Header from '../../footer/Footer'


const mobileindex = () => {
  return (
    <>

    <Header/>
    <MobileAppDevelopment/>
    <Footer/>
      
    </>
  )
}

export default mobileindex
