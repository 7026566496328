import React from 'react'

import Smm from './Smm'
import Footer from '../../header/Header'
import Header from '../../footer/Footer'


const smmindex = () => {
  return (
    <>
      
    <Header/>
    <Smm/>
    <Footer/>

    </>
  )
}

export default smmindex
