import React from 'react'

const Team = () => {
  return (
    <>
      {/* <!-- Start Team Area  About inner--> */}
    <section class="team-area-about-inner single">
        <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" class="about-page-team-area-shep-1 blur-1"/>
        <div class="container">
            <div class="about-page-team team-area">
                <div class="row justify-content-center">
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                        <div class="team-inner">
                            <div class="team-thumb">
                                <img src="assets/images/team/team-1.webp" alt="VRE"/>
                                <div class="team-social-media">
                                    <ul>
                                        <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-name-position">
                                <h6 class="font-size-1-22"><a href="team-details.html">Jerome C. Ramirez</a></h6>
                                <span class="font-size-1-16">CEO & Founder</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                        <div class="team-inner">
                            <div class="team-thumb">
                                <img src="assets/images/team/team-2.webp" alt="VRE"/>
                                <div class="team-social-media">
                                    <ul>
                                        <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-name-position">
                                <h6 class="font-size-1-22"><a href="team-details.html">David George</a></h6>
                                <span class="font-size-1-16">Senior Manager</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                        <div class="team-inner">
                            <div class="team-thumb">
                                <img src="assets/images/team/team-3.webp" alt="VRE"/>
                                <div class="team-social-media">
                                    <ul>
                                        <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-name-position">
                                <h6 class="font-size-1-22"><a href="team-details.html">Matthew</a></h6>
                                <span class="font-size-1-16">IT Support Specialist</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                        <div class="team-inner">
                            <div class="team-thumb">
                                <img src="assets/images/team/team-4.webp" alt="VRE"/>
                                <div class="team-social-media">
                                    <ul>
                                        <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-name-position">
                                <h6 class="font-size-1-22"><a href="team-details.html">Patrick M. Piazza</a></h6>
                                <span class="font-size-1-16">IT Support Specialist</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                        <div class="team-inner">
                            <div class="team-thumb">
                                <img src="assets/images/team/team-5.webp" alt="VRE"/>
                                <div class="team-social-media">
                                    <ul>
                                        <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-name-position">
                                <h6 class="font-size-1-22"><a href="team-details.html">Rimi karman</a></h6>
                                <span class="font-size-1-16">SEO Specialist</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                        <div class="team-inner">
                            <div class="team-thumb">
                                <img src="assets/images/team/team-6.webp" alt="VRE"/>
                                <div class="team-social-media">
                                    <ul>
                                        <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-name-position">
                                <h6 class="font-size-1-22"><a href="team-details.html">Dran Sami</a></h6>
                                <span class="font-size-1-16">Marketing Eng</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                        <div class="team-inner">
                            <div class="team-thumb">
                                <img src="assets/images/team/team-7.webp" alt="VRE"/>
                                <div class="team-social-media">
                                    <ul>
                                        <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-name-position">
                                <h6 class="font-size-1-22"><a href="team-details.html">Angel Mathews</a></h6>
                                <span class="font-size-1-16">Director</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-30">
                        <div class="team-inner">
                            <div class="team-thumb">
                                <img src="assets/images/team/team-8.webp" alt="VRE"/>
                                <div class="team-social-media">
                                    <ul>
                                        <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="team-name-position">
                                <h6 class="font-size-1-22"><a href="team-details.html">Ramirez Maliha</a></h6>
                                <span class="font-size-1-16">Co-Founder</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- End Team Area About inner --> */}
    </>
  )
}

export default Team
