import React from 'react'

const Projects = () => {
  return (
    <>
      
      <section class="bread-crumb-area">
        <div class="container">
            <div class="bread-crumb-wrapper">
                <a href="index.html">Home</a>
                <span>
                    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.90625 7.53125L2.84375 13.625C2.53125 13.9062 2.0625 13.9062 1.78125 13.625L1.0625 12.9062C0.78125 12.625 0.78125 12.1562 1.0625 11.8438L5.875 7L1.0625 2.1875C0.78125 1.875 0.78125 1.40625 1.0625 1.125L1.78125 0.40625C2.0625 0.125 2.53125 0.125 2.84375 0.40625L8.90625 6.5C9.1875 6.78125 9.1875 7.25 8.90625 7.53125Z"
                            fill="white" />
                    </svg>
                </span>
                <a href="#" class="current-page">Our Works</a>
            </div>
            <div class="bread-crumb-title">
                <h2 class="split-collab">Our Works</h2>
            </div>
        </div>
    </section>
    {/* <!-- End Bread Crumb Area Home 1 --> */}

    {/* <!-- Start Recent Work Home 2 --> */}
    <section class="recent-work-home-2 portfolio-p">
        <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" class="recent-work-bg-shep-home-2 blur-1"/>
        <div class="container">
            <div class="recent-work-wrapper">
                <div class="row items-area justify-content-between">
                    <div class="col-xl-6 col-lg-6 col-sm-12">
                        <div class="recent-work-inner vre-reveal-image-one">
                            <div class="recent-work-img vre-reveal-one">
                                <img src="assets/images/project-grid/project-1.webp" alt="VRE"/>
                            </div>
                            <div class="recent-work-thumb vre-slide-down-gsap">
                                <div class="recent-work-text">
                                    <h5 class="font-size-1-24"><a href="project-details.html">Innovative Digital Solutions</a></h5>
                                    <p class="font-size-1-18">Marketing Digital </p>
                                </div>
                                <a href="project-details.html" class="project-view-btn">
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.12109 0.384765L6.71109 1.79477L12.2911 7.38477L0.121093 7.38476L0.121093 9.38476L12.2911 9.38477L6.70109 14.9648L8.12109 16.3848L16.1211 8.38477L8.12109 0.384765Z" fill="white"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-sm-12 mt-55">
                        <div class="recent-work-inner vre-reveal-image-one">
                            <div class="recent-work-img vre-reveal-one">
                                <img src="assets/images/project-grid/project-2.webp" alt="VRE"/>
                            </div>
                            <div class="recent-work-thumb vre-slide-down-gsap">
                                <div class="recent-work-text">
                                    <h5 class="font-size-1-24"><a href="project-details.html">Digital Excellence</a></h5>
                                    <p class="font-size-1-18">Efficiency Management </p>
                                </div>
                                <a href="project-details.html" class="project-view-btn">
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.12109 0.384765L6.71109 1.79477L12.2911 7.38477L0.121093 7.38476L0.121093 9.38476L12.2911 9.38477L6.70109 14.9648L8.12109 16.3848L16.1211 8.38477L8.12109 0.384765Z" fill="white"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-sm-12 mt-55">
                        <div class="recent-work-inner vre-reveal-image-one recent-work-item-3">
                            <div class="recent-work-img vre-reveal-one">
                                <img src="assets/images/project-grid/project-3.webp" alt="VRE"/>
                            </div>
                            <div class="recent-work-thumb vre-slide-down-gsap">
                                <div class="recent-work-text">
                                    <h5 class="font-size-1-24"><a href="project-details.html">Creative Digital Campaign</a></h5>
                                    <p class="font-size-1-18">Market Research </p>
                                </div>
                                <a href="project-details.html" class="project-view-btn">
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.12109 0.384765L6.71109 1.79477L12.2911 7.38477L0.121093 7.38476L0.121093 9.38476L12.2911 9.38477L6.70109 14.9648L8.12109 16.3848L16.1211 8.38477L8.12109 0.384765Z" fill="white"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-sm-12 mt-110">
                        <div class="recent-work-inner vre-reveal-one">
                            <div class="recent-work-img vre-reveal-image-one">
                                <img src="assets/images/project-grid/project-4.webp" alt="VRE"/>
                            </div>
                            <div class="recent-work-thumb vre-slide-down-gsap">
                                <div class="recent-work-text">
                                    <h5 class="font-size-1-24"><a href="project-details.html">Structural Welding</a></h5>
                                    <p class="font-size-1-18">Building Renovation </p>
                                </div>
                                <a href="project-details.html" class="project-view-btn">
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.12109 0.384765L6.71109 1.79477L12.2911 7.38477L0.121093 7.38476L0.121093 9.38476L12.2911 9.38477L6.70109 14.9648L8.12109 16.3848L16.1211 8.38477L8.12109 0.384765Z" fill="white"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- End Recent Work Home 2 --> */}

    {/* <!-- Start Offer Area Home 3 --> */}
    <section class="offer-area-home-3 project-grid-area project-p">
        <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" class="project-grid-area-1-shep-1 blur-1"/>
        <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" class="project-grid-area-1-shep-2 blur-1"/>
        <img src="assets/images/shep/bg-blur-shep-2.png" alt="VRE" class="project-grid-area-1-shep-3 blur-1"/>
        <div class="container">
            <div class="title">
                <div class="sub-title sub-title-home-2">
                    <p>WHAT WE OFFER</p>
                </div>
                <div class="main-title">
                    <h3 class="split-collab">We Offer A Wide Range Of <br/>
                        Digital Marketing <span><img src="assets/images/shep/text-shep-1.png" alt="VRE"/></span></h3>
                </div>
            </div>
            <div class="project-grid-btn mt-80">
                <ul>
                    <li data-filter="*">Show All</li>
                    <li data-filter=".cat-1">Business</li>
                    <li data-filter=".cat-2">Cyber Security</li>
                    <li data-filter=".cat-3">Development</li>
                    <li data-filter=".cat-4">Branding</li>

                </ul>
            </div>
            <div class="offer-wrapper">
                <div class="row justify-content-center project-grid-active">
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="offer-inner three four">
                            <img src="assets/images/project-grid/project-1.webp" alt="VRE"/>
                            <div class="offer-name">
                                <h5 class="font-size-1-24"><a href="project-details.html">Quality Work</a></h5>
                                <span>
                                    <i class="fa-solid fa-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 cat-2 cat-3">
                        <div class="offer-inner">
                            <img src="assets/images/project-grid/project-2.webp" alt="VRE"/>
                            <div class="offer-name">
                                <h5 class="font-size-1-24"><a href="project-details.html">Marketing Agency</a></h5>
                                <span>
                                    <i class="fa-solid fa-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 cat-1 cat4 cat-2">
                        <div class="offer-inner">
                            <img src="assets/images/project-grid/project-3.webp" alt="VRE"/>
                            <div class="offer-name">
                                <h5 class="font-size-1-24"><a href="project-details.html">Professional Team</a></h5>
                                <span>
                                    <i class="fa-solid fa-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 cat-4 cat-2">
                        <div class="offer-inner">
                            <img src="assets/images/project-grid/project-1.webp" alt="VRE"/>
                            <div class="offer-name">
                                <h5 class="font-size-1-24"><a href="project-details.html">Speed Optimization</a></h5>
                                <span>
                                    <i class="fa-solid fa-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 cat-3">
                        <div class="offer-inner">
                            <img src="assets/images/project-grid/project-2.webp" alt="VRE"/>
                            <div class="offer-name">
                                <h5 class="font-size-1-24"><a href="project-details.html">Creative Agency</a></h5>
                                <span>
                                    <i class="fa-solid fa-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 cat-1 cat-2 cat-4">
                        <div class="offer-inner">
                            <img src="assets/images/project-grid/project-1.webp" alt="VRE"/>
                            <div class="offer-name">
                                <h5 class="font-size-1-24"><a href="project-details.html">SEO Marketter</a></h5>
                                <span>
                                    <i class="fa-solid fa-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- End Offer Area Home 3 -->  */}

    </>
  )
}

export default Projects
