import React from 'react'

import WebDevelopment from './WebDevelopment'
import Footer from '../../header/Header'
import Header from '../../footer/Footer'


const webdevelopmentindex = () => {
  return (
    <>

    <Header/>
    <WebDevelopment/>
    <Footer/>

    </>
  )
}

export default webdevelopmentindex
