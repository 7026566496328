import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import Blog from './Blog'


const blogindex = () => {
  return (
    <>
    
      <Header/>
      <Blog/>
      <Footer/>

    </>
  )
}

export default blogindex
