import React from 'react'

import About from './About'
import Header from '../header/Header'
import Footer from '../footer/Footer'

function aboutindex() {
  return (
    <>
      <Header/>
      <About/>
      <Footer/>

    </>
  )
}

export default aboutindex
